import React from "react"
import styled, { ThemeProvider } from "styled-components"
import { Link } from "gatsby"
import { lightTheme } from "styles/theme"
import SEO from "components/seo"

import GlobalStyle from "styles/globalStyle"
import Signin from "components/signin"

const Logo = "https://hrflow-ai.imgix.net/logo-hrflow.svg"


import GlobalStateProvider from "context/provider"


const StyledLayout = styled.div`
`
const StyledBar = styled.div`
  max-width: 90rem;
  margin: auto;
  padding: 1rem;
`
const StyledLogoWrapper = styled(Link)`
  img {
    width: 8rem;
  }
`

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 6rem);

`


const StyledSignin  = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  font-weight: 400;
  font-size: 13px;
  color: #999;
  box-sizing: border-box;
  text-align: center;
  a {
    color: #999 !important;
    font-weight: 600;
    text-decoration: underline;
    margin-left: 5px;
  }
`

const StyledFormWrapper = styled.div`
  width: 400px;
  max-width: 400px;
`
const SigninPage = () => {

  const globalState = {
    // if useSplashScreen=false, we skip the intro by setting isIntroDone=true
    isIntroDone: false,
    // darkMode is initially disabled, a hook inside the Layout component
    // will check the user's preferences and switch to dark mode if needed
    darkMode: false,
  }
  const theme = lightTheme
  return (
    <GlobalStateProvider initialState={globalState}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <StyledLayout>
          <SEO
              frontmatter={{
                title: "Sign in | HrFlow.ai",
                url: "https://hrflow.ai/signin/",
                description: "HrFlow.ai is a human resources data platform (HRDP) that helps you extract, enrich, qualify and control your hr data.",
                keywords: "HR Data, HR AI, HR Analytics, HR Integrations, HR API, HR Workflows"
              }}
          />
          <StyledBar>
            <StyledLogoWrapper to="/">
              <img src={Logo} alt="HrFlow.ai logo" />
            </StyledLogoWrapper>
          </StyledBar>
          <StyledWrapper>
            <StyledFormWrapper>
              <Signin />
              <StyledSignin>
                Forgot your team subdomain?
                  <Link to="/findteam/">
                    Find it here
                  </Link>
              </StyledSignin>
            </StyledFormWrapper>
          </StyledWrapper>
        </StyledLayout>
      </ThemeProvider>
    </GlobalStateProvider>
  )
}

export default SigninPage